import React, {useEffect, useLayoutEffect} from "react";
import "./cookie.scss";

const CookieOneTrustComponent: React.FC = () => {
 // Layout effect waits for DOM to render
  useLayoutEffect(() => {
    handleContentLoaded();  
}, [])


useEffect(() => {
  handleContentLoaded();
}, [])

  return (
    <div>
      <script src="https://cdn-apac.onetrust.com/scripttemplates/otSDKStub.js"  
      data-domain-script="0190549f-143c-78d0-87be-c74fc82ac6b2-test" ></script>
      <script type="text/javascript">
      function OptanonWrapper() {
       }
      </script> 
    </div>
  );
};

export default CookieOneTrustComponent;

function handleContentLoaded() {   
    setTimeout(function(){
      var btnElem = document.getElementById('onetrust-pc-btn-handler');
      var closebtnIcon = document.getElementById('close-pc-btn-handler');
      var acceptclostbtn =document.getElementById('accept-recommended-btn-handler');
      var closebtnClass = document.getElementsByClassName('onetrust-close-btn-handler');

      btnElem?.addEventListener("click", function(){
        if(window.scrollY > 400){
          window.scrollBy({
            top: -35
          });
        }
        else{
          window.scrollTo(0,0)
        }
        document.getElementsByTagName('html')[0].style.overflow = "hidden";
        document.documentElement.style.setProperty("--body-overflow", "auto");
      });     

      closebtnIcon?.addEventListener("click", function(){
        closeOnetrustPoupHandler();
      });
      acceptclostbtn?.addEventListener("click", function(){
        closeOnetrustPoupHandler();
      });

      Array.from(closebtnClass).forEach(function(element) {
        element.addEventListener("click", function(){
          closeOnetrustPoupHandler();
        });
      });  

    }, 6000); 

    setTimeout(function(){
      if(window.navigator.userAgent.indexOf("Edg") > -1 && window.innerWidth <768 ){
        document.getElementsByClassName('ot-pc-footer')[0]?.classList.add('edgemobile')
        document.getElementById('ot-pc-content')?.classList.add('edgemobile')
        document.getElementById('onetrust-pc-sdk')?.classList.add('edgemobile')
      }
    }, 3500);

  };

  function closeOnetrustPoupHandler(){
    setTimeout(function(){
      document.getElementsByTagName('html')[0].style.overflow = "auto";
      document.documentElement.style.setProperty("--body-overflow", "auto");
    },500);
  }

